@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
.color-white {
  color: #fff; }

.color-red {
  color: #ea0101; }

.color-black {
  color: #000; }

.color-pink {
  color: #EF4B6F; }

.color-blue {
  color: #191970; }

.color-gold {
  color: #F2D74A !important; }

.bg-pink {
  background-color: #EF4B6F; }

.border-color-white {
  border-color: #fff; }

.border-color-pink {
  border-color: #EF4B6F; }

.border-color-gold {
  border-color: #F2D74A !important; }

* {
  font-weight: inherit;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

:root {
  --animate-delay: 3.5s; }

.hidden {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Netscape */
  -moz-opacity: 0;
  /* Safari 1.x */
  -khtml-opacity: 0;
  /* Good browsers */
  opacity: 0;
  visibility: hidden; }

[data-aos="right-to-left"] {
  right: -60px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-property: right, -webkit-transform;
  transition-property: right, transform;
  transition-property: right, transform, -webkit-transform; }
  [data-aos="right-to-left"].aos-animate {
    right: 100%;
    -webkit-transform: rotate(-14deg);
            transform: rotate(-14deg); }

a:hover {
  color: #191970; }

a.btn {
  display: block; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

input,
textarea,
select,
button {
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-appearance: none;
  appearance: none; }
  input:required, input:invalid,
  textarea:required,
  textarea:invalid,
  select:required,
  select:invalid,
  button:required,
  button:invalid {
    box-shadow: none; }
  input[type="number"],
  textarea[type="number"],
  select[type="number"],
  button[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button,
  textarea[type="number"]::-webkit-inner-spin-button,
  textarea[type="number"]::-webkit-outer-spin-button,
  select[type="number"]::-webkit-inner-spin-button,
  select[type="number"]::-webkit-outer-spin-button,
  button[type="number"]::-webkit-inner-spin-button,
  button[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; }
  input::-ms-clear,
  textarea::-ms-clear,
  select::-ms-clear,
  button::-ms-clear {
    display: none;
    width: 0;
    height: 0; }
  input::-ms-reveal,
  textarea::-ms-reveal,
  select::-ms-reveal,
  button::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }

.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  background-color: #F7F7F8; }
  body.overflow-hidden {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none; }

html {
  height: auto; }

.top-wrapper {
  max-width: 1300px;
  width: 96%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .top-wrapper .left {
    max-width: 560px; }
  .top-wrapper .right {
    width: 620px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .top-wrapper .right > div {
      width: 100%; }
      .top-wrapper .right > div.icon-star svg {
        display: block;
        height: auto;
        width: 120px; }
      .top-wrapper .right > div.icon-rocket {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end; }
        .top-wrapper .right > div.icon-rocket svg {
          display: block;
          height: auto;
          width: 300px; }
  .top-wrapper h1 {
    font-weight: 300;
    font-size: 48px;
    margin: 0 0 2px;
    letter-spacing: 0.02em; }
  .top-wrapper p {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.02em; }

.content-wrapper {
  max-width: 1400px;
  width: 96%;
  margin: 0 auto; }

.bold {
  font-weight: 600; }

.resend-box {
  color: #191970;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: center; }
  .resend-box span {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline; }

.btn {
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.04em;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  /* IE 5-7 */
  filter: alpha(opacity=1);
  /* Netscape */
  -moz-opacity: 1;
  /* Safari 1.x */
  -khtml-opacity: 1;
  /* Good browsers */
  opacity: 1;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  @media (hover: hover) {
    .btn:not(.disabled):hover {
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=0.8);
      /* Netscape */
      -moz-opacity: 0.8;
      /* Safari 1.x */
      -khtml-opacity: 0.8;
      /* Good browsers */
      opacity: 0.8; } }
  .btn-small {
    font-size: 14px;
    display: block;
    width: 120px;
    height: 36px;
    line-height: 36px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -ms-border-radius: 40px;
    -moz-border-radius: 40px; }
    .btn-small.whit-border {
      line-height: 34px; }
  .btn-medium {
    font-size: 16px;
    width: 160px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -ms-border-radius: 40px;
    -moz-border-radius: 40px; }
    .btn-medium.whit-border {
      line-height: 38px; }
  .btn-big {
    font-size: 18px;
    display: block;
    width: 200px;
    height: 46px;
    line-height: 46px;
    border-radius: 46px;
    -webkit-border-radius: 46px;
    -ms-border-radius: 46px;
    -moz-border-radius: 46px; }
    .btn-big.whit-border {
      line-height: 44px; }
  .btn.whit-border {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid; }
  .btn.whit-icon {
    padding: 0 18px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .btn.whit-icon svg {
      margin: 0 5px -5px 0; }
  .btn.disabled {
    cursor: not-allowed;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.2);
    /* Netscape */
    -moz-opacity: 0.2;
    /* Safari 1.x */
    -khtml-opacity: 0.2;
    /* Good browsers */
    opacity: 0.2; }

@-webkit-keyframes atom {
  from {
    -webkit-transform: none;
            transform: none; }
  to {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

@keyframes atom {
  from {
    -webkit-transform: none;
            transform: none; }
  to {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

@-webkit-keyframes electron-circle1 {
  from {
    -webkit-transform: rotateY(70deg) rotateZ(20deg);
            transform: rotateY(70deg) rotateZ(20deg); }
  to {
    -webkit-transform: rotateY(70deg) rotateZ(380deg);
            transform: rotateY(70deg) rotateZ(380deg); } }

@keyframes electron-circle1 {
  from {
    -webkit-transform: rotateY(70deg) rotateZ(20deg);
            transform: rotateY(70deg) rotateZ(20deg); }
  to {
    -webkit-transform: rotateY(70deg) rotateZ(380deg);
            transform: rotateY(70deg) rotateZ(380deg); } }

@-webkit-keyframes electron1 {
  from {
    -webkit-transform: rotateZ(-20deg) rotateY(-70deg);
            transform: rotateZ(-20deg) rotateY(-70deg); }
  to {
    -webkit-transform: rotateZ(-380deg) rotateY(-70deg);
            transform: rotateZ(-380deg) rotateY(-70deg); } }

@keyframes electron1 {
  from {
    -webkit-transform: rotateZ(-20deg) rotateY(-70deg);
            transform: rotateZ(-20deg) rotateY(-70deg); }
  to {
    -webkit-transform: rotateZ(-380deg) rotateY(-70deg);
            transform: rotateZ(-380deg) rotateY(-70deg); } }

@-webkit-keyframes electron-circle2 {
  from {
    -webkit-transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg);
            transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg); }
  to {
    -webkit-transform: rotateY(60deg) rotateX(60deg) rotateZ(330deg);
            transform: rotateY(60deg) rotateX(60deg) rotateZ(330deg); } }

@keyframes electron-circle2 {
  from {
    -webkit-transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg);
            transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg); }
  to {
    -webkit-transform: rotateY(60deg) rotateX(60deg) rotateZ(330deg);
            transform: rotateY(60deg) rotateX(60deg) rotateZ(330deg); } }

@-webkit-keyframes electron2 {
  from {
    -webkit-transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg);
            transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg); }
  to {
    -webkit-transform: rotateZ(-330deg) rotateX(-60deg) rotateY(-60deg);
            transform: rotateZ(-330deg) rotateX(-60deg) rotateY(-60deg); } }

@keyframes electron2 {
  from {
    -webkit-transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg);
            transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg); }
  to {
    -webkit-transform: rotateZ(-330deg) rotateX(-60deg) rotateY(-60deg);
            transform: rotateZ(-330deg) rotateX(-60deg) rotateY(-60deg); } }

@-webkit-keyframes electron-circle3 {
  from {
    -webkit-transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg);
            transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg); }
  to {
    -webkit-transform: rotateY(-60deg) rotateX(60deg) rotateZ(460deg);
            transform: rotateY(-60deg) rotateX(60deg) rotateZ(460deg); } }

@keyframes electron-circle3 {
  from {
    -webkit-transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg);
            transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg); }
  to {
    -webkit-transform: rotateY(-60deg) rotateX(60deg) rotateZ(460deg);
            transform: rotateY(-60deg) rotateX(60deg) rotateZ(460deg); } }

@-webkit-keyframes electron3 {
  from {
    -webkit-transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg);
            transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg); }
  to {
    -webkit-transform: rotateZ(-460deg) rotateX(-60deg) rotateY(60deg);
            transform: rotateZ(-460deg) rotateX(-60deg) rotateY(60deg); } }

@keyframes electron3 {
  from {
    -webkit-transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg);
            transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg); }
  to {
    -webkit-transform: rotateZ(-460deg) rotateX(-60deg) rotateY(60deg);
            transform: rotateZ(-460deg) rotateX(-60deg) rotateY(60deg); } }

.loader-box {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99; }
  .loader-box:after {
    background-color: #fff;
    content: '';
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%; }
  .loader-box.transparent:after {
    background-color: rgba(255, 255, 255, 0.7); }

.atom {
  z-index: 1;
  margin: 0 auto;
  width: 120px;
  height: 120px;
  position: relative;
  -webkit-animation: atom 1s ease-in-out infinite alternate;
          animation: atom 1s ease-in-out infinite alternate;
  -webkit-perspective: 300px;
          perspective: 300px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }
  .atom:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #171058; }
  .atom .electron {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 2px solid #191970;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; }
    .atom .electron:before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      margin: auto;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: #191970;
      -webkit-transform-origin: 50% 50% 0;
              transform-origin: 50% 50% 0; }
    .atom .electron:nth-child(1) {
      -webkit-transform: rotateY(70deg) rotateZ(20deg);
              transform: rotateY(70deg) rotateZ(20deg);
      -webkit-animation: electron-circle1 3s linear infinite;
              animation: electron-circle1 3s linear infinite; }
      .atom .electron:nth-child(1):before {
        -webkit-transform: rotateZ(-20deg) rotateY(-70deg);
                transform: rotateZ(-20deg) rotateY(-70deg);
        -webkit-animation: electron1 3s linear infinite;
                animation: electron1 3s linear infinite; }
    .atom .electron:nth-child(2) {
      -webkit-transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg);
              transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg);
      -webkit-animation: electron-circle2 3s linear infinite;
              animation: electron-circle2 3s linear infinite; }
      .atom .electron:nth-child(2):before {
        -webkit-transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg);
                transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg);
        -webkit-animation: electron2 3s linear infinite;
                animation: electron2 3s linear infinite; }
    .atom .electron:nth-child(3) {
      -webkit-transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg);
              transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg);
      -webkit-animation: electron-circle3 3s linear infinite;
              animation: electron-circle3 3s linear infinite; }
      .atom .electron:nth-child(3):before {
        -webkit-transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg);
                transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg);
        -webkit-animation: electron3 3s linear infinite;
                animation: electron3 3s linear infinite; }

.react-tel-input .form-control {
  width: 100%; }

.react-tel-input:not(.disabled):hover .form-control,
.react-tel-input:not(.disabled):hover .flag-dropdown {
  border-color: #8383ad; }

.react-tel-input:not(.disabled):focus .form-control,
.react-tel-input:not(.disabled):focus .flag-dropdown {
  border-color: #8383ad;
  box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1);
  -moz-box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1); }

.react-tel-input:not(.disabled)-focused .form-control,
.react-tel-input:not(.disabled)-focused .flag-dropdown {
  border-color: #8383ad;
  box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1);
  -moz-box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1); }

.register-link {
  font-size: 15px;
  color: #191970; }
  .register-link a {
    color: #191970;
    text-decoration: underline;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=1);
    /* Netscape */
    -moz-opacity: 1;
    /* Safari 1.x */
    -khtml-opacity: 1;
    /* Good browsers */
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out; }
    @media (hover: hover) {
      .register-link a:hover {
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=0.8);
        /* Netscape */
        -moz-opacity: 0.8;
        /* Safari 1.x */
        -khtml-opacity: 0.8;
        /* Good browsers */
        opacity: 0.8; } }

@media screen and (max-width: 1024px) {
  .content-wrapper {
    width: 90% !important; } }

.ant-radio-input:focus + .ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner {
  border-color: #EF4B6F; }

.ant-input {
  height: 38px;
  font-size: 14px;
  letter-spacing: 0.02em;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -ms-border-radius: 6px;
  -moz-border-radius: 6px;
  transition: none 0.3s ease-in-out;
  -webkit-transition: none 0.3s ease-in-out;
  -moz-transition: none 0.3s ease-in-out; }
  .ant-input[disabled] {
    opacity: .4; }
  .ant-input-affix-wrapper {
    font-size: 14px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px; }
    .ant-input-affix-wrapper:focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-affix-wrapper:focus input {
        border-color: currentColor !important;
        border-color: initial !important;
        box-shadow: none !important;
        box-shadow: initial !important; }
    .ant-input-affix-wrapper:hover {
      border-color: #171058; }
    .ant-input-affix-wrapper-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-affix-wrapper-focused input {
        border-color: currentColor !important;
        border-color: initial !important;
        box-shadow: none !important;
        box-shadow: initial !important; }
    .ant-input-affix-wrapper input {
      height: auto; }
  .ant-input-group-wrapper {
    font-size: 14px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px; }
    .ant-input-group-wrapper:focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-group-wrapper:focus input {
        border-color: currentColor !important;
        border-color: initial !important;
        box-shadow: none !important;
        box-shadow: initial !important; }
    .ant-input-group-wrapper:hover {
      border-color: #171058; }
    .ant-input-group-wrapper-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-group-wrapper-focused input {
        border-color: currentColor !important;
        border-color: initial !important;
        box-shadow: none !important;
        box-shadow: initial !important; }
    .ant-input-group-wrapper input {
      height: 100%; }
  .ant-input-group {
    height: 38px; }
  .ant-input:not([disabled]):hover {
    border-color: #8383ad; }
  .ant-input:not([disabled]):focus {
    border-color: #8383ad;
    box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important;
    -webkit-box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important;
    -moz-box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important; }
  .ant-input:not([disabled])-focused {
    border-color: #8383ad;
    box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important;
    -webkit-box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important;
    -moz-box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important; }
  .ant-input-search input {
    height: 38px; }
  .ant-input-search-button {
    height: 38px;
    width: 38px; }
    .ant-input-search-button svg {
      width: 14px;
      height: 14px; }
  .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #171058; }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:focus {
      border-color: #171058; }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:hover {
      border-color: #171058; }
  .ant-input-number {
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px; }
    .ant-input-number-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number-focused input {
        border-color: currentColor !important;
        border-color: initial !important;
        box-shadow: none !important;
        box-shadow: initial !important; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled):focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number:not(.ant-input-number.ant-input-number-disabled):focus input {
        border-color: currentColor !important;
        border-color: initial !important;
        box-shadow: none !important;
        box-shadow: initial !important; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled):hover {
      border-color: #171058; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled)-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number:not(.ant-input-number.ant-input-number-disabled)-focused input {
        border-color: currentColor !important;
        border-color: initial !important;
        box-shadow: none !important;
        box-shadow: initial !important; }
    .ant-input-number-input {
      height: 36px;
      font-size: 14px; }
    .ant-input-number-disabled {
      opacity: .4; }

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #171058; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(25, 25, 112, 0.2); }

.ant-select-item-option-content span {
  font-weight: 600; }

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #171058;
  box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important;
  -webkit-box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important;
  -moz-box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selection-item, .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-placeholder {
  line-height: 38px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
  font-size: 14px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -ms-border-radius: 6px;
  -moz-border-radius: 6px; }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%; }

.ant-select-selection-item span {
  font-weight: 600; }

.ant-form-item {
  display: block;
  margin: 0 0 30px; }
  .ant-form-item-label {
    text-align: left;
    display: block; }
    .ant-form-item-label > label {
      height: auto;
      color: #191970;
      font-weight: 700;
      font-size: 10px;
      letter-spacing: 0.5em;
      text-transform: uppercase;
      margin: 0 0 5px 2px; }
      .ant-form-item-label > label:after, .ant-form-item-label > label:before {
        display: none; }
  .ant-form-item-required:after, .ant-form-item-required:before {
    display: none !important; }
  .ant-form-item-explain {
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: -28px; }

.ant-form .response-box {
  color: #ea0101;
  margin: 0 0 18px;
  font-weight: 400;
  font-size: 14px; }
  .ant-form .response-box.success {
    color: #191970; }

.ant-radio-group {
  line-height: 22px; }

.ant-radio-wrapper {
  font-weight: 600;
  font-size: 15px;
  color: #8E8EA0; }
  .ant-radio-wrapper-checked {
    color: #EF4B6F; }

.ant-radio-inner {
  border: 1px solid #8E8EA0; }

.ant-radio-checked .ant-radio-inner {
  border-color: #EF4B6F; }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #EF4B6F; }

.ant-modal {
  width: 680px; }
  .ant-modal-body {
    padding: 0; }
  .ant-modal-title {
    font-weight: 400;
    font-size: 24px;
    color: #191970;
    text-align: center;
    margin: 0 0 30px; }
  .ant-modal-content {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    -moz-border-radius: 20px;
    padding: 40px; }
    .ant-modal-content .ant-row:last-child {
      margin: 0; }
    .ant-modal-content button:not(.ant-modal-close) {
      margin: 30px auto 0; }
  .ant-modal .stripe-form .title {
    text-align: center; }
  .ant-modal .stripe-form .text {
    height: auto;
    color: #191970;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    margin: 0 0 2px 2px; }
  .ant-modal .stripe-form input,
  .ant-modal .stripe-form .StripeElement {
    display: block;
    margin: 0 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    outline: 0;
    border-radius: 4px;
    background: white;
    height: 38px;
    font-size: 14px;
    border: 1px solid #8383ad;
    letter-spacing: 0.02em;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px;
    transition: none 0.3s ease-in-out;
    -webkit-transition: none 0.3s ease-in-out;
    -moz-transition: none 0.3s ease-in-out; }
  .ant-modal .stripe-form input::-webkit-input-placeholder {
    color: #aab7c4; }
  .ant-modal .stripe-form input:-ms-input-placeholder {
    color: #aab7c4; }
  .ant-modal .stripe-form input::placeholder {
    color: #aab7c4; }
  .ant-modal.stripe-modal .stripe-logo {
    height: auto;
    width: 100px;
    display: block;
    position: absolute;
    right: 18px;
    bottom: 15px; }
  .ant-modal.stripe-modal .ant-modal-content {
    padding: 40px 40px 80px; }
  .ant-modal.stripe-modal .ant-modal-title {
    margin: 0 0 50px; }
  .ant-modal-login-register {
    max-width: 580px;
    width: 96% !important;
    box-sizing: border-box; }
    .ant-modal-login-register .wrap-box {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      text-align: center; }
    .ant-modal-login-register a {
      display: block;
      font-size: 16px;
      font-weight: 700;
      color: #191970;
      padding: 0 0 10px; }
      .ant-modal-login-register a .icon {
        margin: 0 0 20px; }
      .ant-modal-login-register a img {
        display: block;
        height: auto;
        width: 100%;
        max-width: 230px;
        margin: 0 auto; }
  .ant-modal-results {
    max-width: 580px;
    width: 96% !important;
    box-sizing: border-box; }
    .ant-modal-results .wrap-box-results {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      text-align: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .ant-modal-results a {
      display: block;
      font-size: 16px;
      font-weight: 700;
      color: #191970;
      padding: 0 0 10px;
      text-decoration: underline; }
      .ant-modal-results a .icon {
        margin: 0 0 20px; }
      .ant-modal-results a img {
        display: block;
        height: auto;
        width: 100%;
        max-width: 230px;
        margin: 0 auto; }

.ant-tooltip-inner {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -ms-border-radius: 6px;
  -moz-border-radius: 6px;
  background-color: #171058; }

.ant-tooltip-arrow-content {
  background-color: #171058; }

.ant-pagination-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link, .ant-pagination-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link {
  color: #171058; }

.ant-pagination-item:hover a, .ant-pagination-item:focus a {
  color: #171058; }

.ant-pagination-item a {
  color: #171058; }

.ant-pagination-item-active {
  border-color: #171058; }
  .ant-pagination-item-active:hover, .ant-pagination-item-active:focus {
    border-color: #171058; }
  .ant-pagination-item-active a {
    color: #171058; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  -webkit-transform: translate3d(0, 100px, 0);
          transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  -webkit-transform: translate3d(0, -100px, 0);
          transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  -webkit-transform: translate3d(-100px, 0, 0);
          transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  -webkit-transform: translate3d(100px, 0, 0);
          transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  -webkit-transform: translate3d(-100px, 100px, 0);
          transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  -webkit-transform: translate3d(100px, 100px, 0);
          transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  -webkit-transform: translate3d(-100px, -100px, 0);
          transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  -webkit-transform: translate3d(100px, -100px, 0);
          transform: translate3d(100px, -100px, 0); }

[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  -webkit-transform: scale(0.6);
          transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  -webkit-transform: translate3d(0, 100px, 0) scale(0.6);
          transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  -webkit-transform: translate3d(0, -100px, 0) scale(0.6);
          transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  -webkit-transform: translate3d(-100px, 0, 0) scale(0.6);
          transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  -webkit-transform: translate3d(100px, 0, 0) scale(0.6);
          transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
          transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
          transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
          transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
          transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^='slide'][data-aos^='slide'] {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0); }

[data-aos^='flip'][data-aos^='flip'] {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

[data-aos='flip-left'] {
  -webkit-transform: perspective(2500px) rotateY(-100deg);
          transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
            transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  -webkit-transform: perspective(2500px) rotateY(100deg);
          transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
            transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  -webkit-transform: perspective(2500px) rotateX(-100deg);
          transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
            transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  -webkit-transform: perspective(2500px) rotateX(100deg);
          transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
            transform: perspective(2500px) rotateX(0); }

header {
  padding: 24px 0;
  width: 100%; }
  header-wrapper {
    max-width: calc(100% - 72px);
    width: 100%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  header-logo svg {
    display: block;
    width: 220px;
    height: auto; }
  header-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    header-menu-item:not(:last-child) {
      margin: 0 40px 0 0; }
    header-menu-item:not(.button-wrap) a {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      letter-spacing: 0.04em;
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=1);
      /* Netscape */
      -moz-opacity: 1;
      /* Safari 1.x */
      -khtml-opacity: 1;
      /* Good browsers */
      opacity: 1;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease; }
      @media (hover: hover) {
        header-menu-item:not(.button-wrap) a:hover {
          /* IE 8 */
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
          /* IE 5-7 */
          filter: alpha(opacity=0.8);
          /* Netscape */
          -moz-opacity: 0.8;
          /* Safari 1.x */
          -khtml-opacity: 0.8;
          /* Good browsers */
          opacity: 0.8; } }
      header-menu-item:not(.button-wrap) a.whit-icon {
        -webkit-align-items: center;
                align-items: center;
        display: -webkit-flex;
        display: flex; }
        header-menu-item:not(.button-wrap) a.whit-icon svg {
          margin: 0 10px 0 0; }
    header-menu-item.button-wrap {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      header-menu-item.button-wrap div {
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=1);
        /* Netscape */
        -moz-opacity: 1;
        /* Safari 1.x */
        -khtml-opacity: 1;
        /* Good browsers */
        opacity: 1;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease; }
        header-menu-item.button-wrap div:first-child {
          margin: 0 18px 0 0; }
        @media (hover: hover) {
          header-menu-item.button-wrap div:hover {
            /* IE 8 */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
            /* IE 5-7 */
            filter: alpha(opacity=0.8);
            /* Netscape */
            -moz-opacity: 0.8;
            /* Safari 1.x */
            -khtml-opacity: 0.8;
            /* Good browsers */
            opacity: 0.8; } }
    header-menu-button {
      display: none;
      cursor: pointer;
      z-index: 1; }
      header-menu-button i {
        display: block;
        cursor: pointer;
        width: 20px;
        height: 16px;
        position: relative;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: .5s ease-in-out; }
        header-menu-button i span {
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
          background: #fff;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          transition: .25s ease-in-out; }
          header-menu-button i span:nth-child(1) {
            top: 0; }
          header-menu-button i span:nth-child(2) {
            top: 6px; }
          header-menu-button i span:nth-child(3) {
            top: 6px; }
          header-menu-button i span:nth-child(4) {
            top: 12px; }
      header-menu-button.clicked i span:nth-child(1) {
        width: 0;
        left: 50%;
        opacity: 0; }
      header-menu-button.clicked i span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header-menu-button.clicked i span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
      header-menu-button.clicked i span:nth-child(4) {
        width: 0;
        left: 50%;
        opacity: 0; }
  header .user-tooltip {
    position: relative; }
    header .user-tooltip-box {
      min-width: 140px;
      right: 0;
      width: 100%;
      position: absolute;
      top: 45px;
      background-color: #fff;
      box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
      -webkit-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
      -moz-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -ms-border-radius: 10px;
      -moz-border-radius: 10px; }
      header .user-tooltip-box a, header .user-tooltip-box div {
        cursor: pointer;
        padding: 10px 25px;
        display: block;
        font-size: 14px;
        font-weight: 600;
        color: #191970;
        letter-spacing: 0.04em;
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=1);
        /* Netscape */
        -moz-opacity: 1;
        /* Safari 1.x */
        -khtml-opacity: 1;
        /* Good browsers */
        opacity: 1;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease; }
        @media (hover: hover) {
          header .user-tooltip-box a:hover, header .user-tooltip-box div:hover {
            /* IE 8 */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
            /* IE 5-7 */
            filter: alpha(opacity=0.8);
            /* Netscape */
            -moz-opacity: 0.8;
            /* Safari 1.x */
            -khtml-opacity: 0.8;
            /* Good browsers */
            opacity: 0.8; } }
      header .user-tooltip-box a {
        border-bottom: 1px solid #E9E9EF; }
      header .user-tooltip-box div {
        color: #EF4B6F; }
  header.menu-open header-logo {
    position: relative;
    z-index: 9; }
  header.menu-open header-menu {
    z-index: 1;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.9);
    display: -webkit-flex;
    display: flex;
    position: fixed;
    height: 100vh;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: center; }
    header.menu-open header-menu-item {
      width: 100%; }
      header.menu-open header-menu-item:not(:last-child) {
        margin: 0 auto 25px; }
      header.menu-open header-menu-item:not(.button-wrap) a {
        font-size: 14px; }
      header.menu-open header-menu-item.button-wrap {
        display: block;
        margin: 20px 0; }
        header.menu-open header-menu-item.button-wrap div {
          width: 120px;
          line-height: 34px;
          height: 36px;
          margin: 0 auto;
          font-size: 14px; }
          header.menu-open header-menu-item.button-wrap div:not(:last-child) {
            margin: 0 auto 20px; }

@media screen and (max-width: 1280px) {
  header-logo svg {
    width: 180px; }
  header-menu-item:not(:last-child) {
    margin: 0 24px 0 0; }
  header-menu-item:not(.button-wrap) a {
    font-size: 13px; }
  header-menu-item.button-wrap a {
    line-height: 32px !important;
    height: 34px;
    font-size: 13px;
    width: 100px; } }

@media screen and (max-width: 1040px) {
  header-menu {
    display: none; }
    header-menu-button {
      display: block; }
    header-menu-item:not(.button-wrap) a.btn {
      margin: 0 auto; } }

footer {
  background-color: #171058;
  padding: 80px 0 50px;
  position: relative; }
  footer .pattern {
    position: absolute;
    width: 100%;
    height: 60px;
    top: -1px;
    background-image: url(/static/media/FooterMask.9c74c714.svg);
    background-size: 120%;
    background-position: center top;
    background-repeat: no-repeat; }
  footer-wrapper {
    display: -webkit-flex;
    display: flex;
    max-width: 1490px;
    width: 96%;
    margin: 0 auto;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.02em; }
  footer .item.col-1 {
    width: 460px; }
    footer .item.col-1 .logo {
      margin: 0 0 80px; }
      footer .item.col-1 .logo svg {
        display: block;
        height: auto;
        width: 190px; }
    footer .item.col-1 .description {
      margin: 20px 0 58px;
      line-height: 1.6;
      font-size: 14px; }
    footer .item.col-1 .description-bold {
      line-height: 1.6;
      font-size: 14px;
      font-weight: 600; }
    footer .item.col-1 .description-subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      margin-top: 30px; }
  footer .item.col-3 {
    width: 280px; }
    footer .item.col-3 .text {
      line-height: 25px; }
  footer .copy-right-mobile {
    display: none; }
  footer .footer-menu li:not(:last-child) {
    margin-bottom: 13px; }
  footer .footer-menu li a {
    font-weight: 400;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=1);
    /* Netscape */
    -moz-opacity: 1;
    /* Safari 1.x */
    -khtml-opacity: 1;
    /* Good browsers */
    opacity: 1;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease; }
    @media (hover: hover) {
      footer .footer-menu li a:hover {
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=0.8);
        /* Netscape */
        -moz-opacity: 0.8;
        /* Safari 1.x */
        -khtml-opacity: 0.8;
        /* Good browsers */
        opacity: 0.8; } }
    footer .footer-menu li a.bold {
      font-weight: 600; }
  footer .social {
    margin: 9px 0 0; }
    footer .social .social-icon-box {
      margin: 10px 0 0;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      footer .social .social-icon-box .social-item:not(:last-child) {
        margin-right: 20px; }
      footer .social .social-icon-box svg {
        display: block;
        width: 30px;
        height: auto;
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=1);
        /* Netscape */
        -moz-opacity: 1;
        /* Safari 1.x */
        -khtml-opacity: 1;
        /* Good browsers */
        opacity: 1;
        transition: all 0.1s ease;
        -webkit-transition: all 0.1s ease;
        -moz-transition: all 0.1s ease; }
      @media (hover: hover) {
        footer .social .social-icon-box a:hover svg {
          /* IE 8 */
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
          /* IE 5-7 */
          filter: alpha(opacity=0.8);
          /* Netscape */
          -moz-opacity: 0.8;
          /* Safari 1.x */
          -khtml-opacity: 0.8;
          /* Good browsers */
          opacity: 0.8; } }

@media screen and (max-width: 1040px) {
  footer {
    padding: 60px 0 40px; }
    footer-wrapper {
      display: block;
      text-align: center; }
    footer .item {
      width: 100% !important; }
      footer .item.col-1 .logo {
        margin: 0 0 30px; }
        footer .item.col-1 .logo svg {
          max-width: 170px; }
        footer .item.col-1 .logo a {
          display: inline-block; }
      footer .item.col-2 {
        margin: 0 0 30px; }
    footer .social-icon-box {
      -webkit-justify-content: center;
              justify-content: center; }
    footer .copy-right {
      display: none; }
      footer .copy-right-mobile {
        margin: 30px 0 0;
        display: block; } }

@-webkit-keyframes move {
  25% {
    opacity: 1; }
  33% {
    opacity: 1;
    -webkit-transform: translateY(30px);
            transform: translateY(30px); }
  67% {
    opacity: 1;
    -webkit-transform: translateY(40px);
            transform: translateY(40px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
            transform: translateY(55px) scale3d(0.5, 0.5, 0.5); } }

@keyframes move {
  25% {
    opacity: 1; }
  33% {
    opacity: 1;
    -webkit-transform: translateY(30px);
            transform: translateY(30px); }
  67% {
    opacity: 1;
    -webkit-transform: translateY(40px);
            transform: translateY(40px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
            transform: translateY(55px) scale3d(0.5, 0.5, 0.5); } }

@-webkit-keyframes translate {
  0% {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px); }
  5% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px); }
  10% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  15% {
    -webkit-transform: translateY(-55px);
            transform: translateY(-55px); }
  20% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  25% {
    -webkit-transform: translateY(-55px);
            transform: translateY(-55px); }
  30% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  35% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px); }
  40% {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px); }
  45% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); }
  50% {
    -webkit-transform: translateY(-85px);
            transform: translateY(-85px); }
  55% {
    -webkit-transform: translateY(-95px);
            transform: translateY(-95px); }
  60% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px); }
  65% {
    -webkit-transform: translateY(-105px);
            transform: translateY(-105px); }
  70% {
    -webkit-transform: translateY(-110px);
            transform: translateY(-110px); }
  75% {
    -webkit-transform: translateY(-115px);
            transform: translateY(-115px); }
  80% {
    -webkit-transform: translateY(-110px);
            transform: translateY(-110px); }
  85% {
    -webkit-transform: translateY(-95px);
            transform: translateY(-95px); }
  90% {
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px); }
  95% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); }
  100% {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px); } }

@keyframes translate {
  0% {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px); }
  5% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px); }
  10% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  15% {
    -webkit-transform: translateY(-55px);
            transform: translateY(-55px); }
  20% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  25% {
    -webkit-transform: translateY(-55px);
            transform: translateY(-55px); }
  30% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  35% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px); }
  40% {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px); }
  45% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); }
  50% {
    -webkit-transform: translateY(-85px);
            transform: translateY(-85px); }
  55% {
    -webkit-transform: translateY(-95px);
            transform: translateY(-95px); }
  60% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px); }
  65% {
    -webkit-transform: translateY(-105px);
            transform: translateY(-105px); }
  70% {
    -webkit-transform: translateY(-110px);
            transform: translateY(-110px); }
  75% {
    -webkit-transform: translateY(-115px);
            transform: translateY(-115px); }
  80% {
    -webkit-transform: translateY(-110px);
            transform: translateY(-110px); }
  85% {
    -webkit-transform: translateY(-95px);
            transform: translateY(-95px); }
  90% {
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px); }
  95% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); }
  100% {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px); } }

@-webkit-keyframes translate1680 {
  0% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); }
  5% {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px); }
  10% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  15% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  20% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  25% {
    -webkit-transform: translateY(-35px);
            transform: translateY(-35px); }
  30% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px); }
  35% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  40% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  45% {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px); }
  50% {
    -webkit-transform: translateY(-95px);
            transform: translateY(-95px); }
  55% {
    -webkit-transform: translateY(-105px);
            transform: translateY(-105px); }
  60% {
    -webkit-transform: translateY(-110px);
            transform: translateY(-110px); }
  65% {
    -webkit-transform: translateY(-115px);
            transform: translateY(-115px); }
  70% {
    -webkit-transform: translateY(-120px);
            transform: translateY(-120px); }
  75% {
    -webkit-transform: translateY(-115px);
            transform: translateY(-115px); }
  80% {
    -webkit-transform: translateY(-110px);
            transform: translateY(-110px); }
  85% {
    -webkit-transform: translateY(-105px);
            transform: translateY(-105px); }
  90% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px); }
  95% {
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px); }
  100% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); } }

@keyframes translate1680 {
  0% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); }
  5% {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px); }
  10% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  15% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  20% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  25% {
    -webkit-transform: translateY(-35px);
            transform: translateY(-35px); }
  30% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px); }
  35% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  40% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  45% {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px); }
  50% {
    -webkit-transform: translateY(-95px);
            transform: translateY(-95px); }
  55% {
    -webkit-transform: translateY(-105px);
            transform: translateY(-105px); }
  60% {
    -webkit-transform: translateY(-110px);
            transform: translateY(-110px); }
  65% {
    -webkit-transform: translateY(-115px);
            transform: translateY(-115px); }
  70% {
    -webkit-transform: translateY(-120px);
            transform: translateY(-120px); }
  75% {
    -webkit-transform: translateY(-115px);
            transform: translateY(-115px); }
  80% {
    -webkit-transform: translateY(-110px);
            transform: translateY(-110px); }
  85% {
    -webkit-transform: translateY(-105px);
            transform: translateY(-105px); }
  90% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px); }
  95% {
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px); }
  100% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); } }

@-webkit-keyframes translate1024 {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  5% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px); }
  10% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  15% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  20% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  45% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  60% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  70% {
    -webkit-transform: translateY(-55px);
            transform: translateY(-55px); }
  80% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px); }
  90% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  100% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); } }

@keyframes translate1024 {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  5% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px); }
  10% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  15% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  20% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  45% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  60% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  70% {
    -webkit-transform: translateY(-55px);
            transform: translateY(-55px); }
  80% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px); }
  90% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  100% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); } }

@-webkit-keyframes translate520 {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  5% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  10% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  15% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  20% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  25% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  35% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  40% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px); }
  45% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(-35px);
            transform: translateY(-35px); }
  55% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  60% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  65% {
    -webkit-transform: translateY(-35px);
            transform: translateY(-35px); }
  70% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  75% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px); }
  80% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  85% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  90% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  95% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

@keyframes translate520 {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  5% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  10% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  15% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  20% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  25% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  35% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  40% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px); }
  45% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(-35px);
            transform: translateY(-35px); }
  55% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  60% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  65% {
    -webkit-transform: translateY(-35px);
            transform: translateY(-35px); }
  70% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  75% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px); }
  80% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  85% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  90% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  95% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

@-webkit-keyframes ibm {
  0% {
    left: 0; }
  5% {
    left: 5%; }
  10% {
    left: 10%; }
  15% {
    left: 15%; }
  20% {
    left: 20%; }
  25% {
    left: 25%; }
  30% {
    left: 30%; }
  35% {
    left: 35%; }
  40% {
    left: 40%; }
  45% {
    left: 45%; }
  50% {
    left: 50%; }
  55% {
    left: 55%; }
  60% {
    left: 60%; }
  65% {
    left: 65%; }
  70% {
    left: 70%; }
  75% {
    left: 75%; }
  80% {
    left: 80%; }
  85% {
    left: 85%; }
  90% {
    left: 90%; }
  95% {
    left: 95%; }
  100% {
    left: 100%; } }

@keyframes ibm {
  0% {
    left: 0; }
  5% {
    left: 5%; }
  10% {
    left: 10%; }
  15% {
    left: 15%; }
  20% {
    left: 20%; }
  25% {
    left: 25%; }
  30% {
    left: 30%; }
  35% {
    left: 35%; }
  40% {
    left: 40%; }
  45% {
    left: 45%; }
  50% {
    left: 50%; }
  55% {
    left: 55%; }
  60% {
    left: 60%; }
  65% {
    left: 65%; }
  70% {
    left: 70%; }
  75% {
    left: 75%; }
  80% {
    left: 80%; }
  85% {
    left: 85%; }
  90% {
    left: 90%; }
  95% {
    left: 95%; }
  100% {
    left: 100%; } }

.page-home header {
  position: absolute;
  z-index: 9; }

.page-home .competitionInfo {
  font-size: 16px;
  color: #120a3f; }

.page-home .competitionDate {
  font-size: 16px;
  color: #34bf44; }

.page-home .info {
  width: 20px;
  margin-left: 10px;
  padding-bottom: 18px; }

.page-home .pointer {
  cursor: pointer; }

.page-home .m-t-10 {
  margin-top: 10px !important; }

.page-home .main {
  overflow: hidden;
  padding: 100px 0;
  background-color: #171058;
  position: relative;
  background-image: url(/static/media/main-bg.7ee7b05a.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  background-attachment: fixed; }
  .page-home .main .scroll-btn {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 80px; }
    .page-home .main .scroll-btn > * {
      display: inline-block;
      line-height: 18px;
      font-size: 13px;
      font-weight: normal;
      color: #ffffff;
      letter-spacing: 2px; }
    .page-home .main .scroll-btn .mouse {
      background: #111b72;
      margin: 0 auto;
      position: relative;
      display: block;
      width: 35px;
      height: 55px;
      box-sizing: border-box;
      border: 3px solid white;
      border-radius: 23px; }
      .page-home .main .scroll-btn .mouse > * {
        position: absolute;
        display: block;
        top: 29%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin: -4px 0 0 -4px;
        background: white;
        border-radius: 50%;
        -webkit-animation: ani-mouse 2.5s linear infinite;
        animation: ani-mouse 2.5s linear infinite; }
  .page-home .main .view-more {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 70px;
    opacity: 1;
    width: 24px;
    margin: 0 auto;
    height: 50px; }
    .page-home .main .view-more .container {
      position: relative;
      width: 24px;
      height: 24px; }
    .page-home .main .view-more .chevron {
      position: absolute;
      width: 24px;
      height: 5px;
      opacity: 0;
      -webkit-transform: scale3d(0.5, 0.5, 0.5);
              transform: scale3d(0.5, 0.5, 0.5);
      -webkit-animation: move 3s ease-out infinite;
              animation: move 3s ease-out infinite; }
      .page-home .main .view-more .chevron:first-child {
        -webkit-animation: move 3s ease-out 1s infinite;
                animation: move 3s ease-out 1s infinite; }
      .page-home .main .view-more .chevron:nth-child(2) {
        -webkit-animation: move 3s ease-out 2s infinite;
                animation: move 3s ease-out 2s infinite; }
      .page-home .main .view-more .chevron:after, .page-home .main .view-more .chevron:before {
        content: " ";
        position: absolute;
        top: 0;
        height: 100%;
        width: 51%;
        background: #fff; }
      .page-home .main .view-more .chevron:before {
        left: 0;
        -webkit-transform: skew(0deg, 30deg);
                transform: skew(0deg, 30deg); }
      .page-home .main .view-more .chevron:after {
        right: 0;
        width: 50%;
        -webkit-transform: skew(0deg, -30deg);
                transform: skew(0deg, -30deg); }
  .page-home .main-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    height: 100%; }
  .page-home .main .content-wrapper {
    max-width: 1550px; }
  .page-home .main h1 {
    font-weight: 600;
    font-size: 50px;
    color: #fff;
    line-height: 1.4; }
  .page-home .main .green {
    color: #94e24b !important; }
  .page-home .main .u-line {
    text-decoration: underline !important;
    -webkit-text-underline-position: under;
    -ms-text-underline-position: under;
    text-underline-position: under; }
  .page-home .main .competitionTitle {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin-top: 1.5%;
    margin-bottom: 1.5%; }
  .page-home .main .afterRegAccess {
    color: #8c88b4;
    font-size: 13.5px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 2%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcIAAAAHCAYAAACbSk32AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFISURBVHgB7ZpNDsIgEIUhcdGlx/AYvf/KY7jssrsKVZsJmelAAcH6voRQfotTZ55grQHgz1iWZXDZYMpSY84W9+C4mrrU/Fw1117bLq3v55ky+3Bt8ztpfSdtnLU2Zn0q1oDucIFa+8LvtccEldjAk+J4qcHsqFO3EgMAQN/MQfJMJJ9J/nAiuonqaYVQ+NUfW+fhArXUN7Vemh8AAMB3WAXRpfvFVIDZ0dByKA5aORzPlaU6AM6EdKTUA0WOqDKobZsS8+fMkWLfo89Ci73cNR1Dr3uOx5/n4EXQ22radoROvG7mtXhqCEnAYsQLtEdzvBjHjHGqFAc/GgxyAm2NIDnToxUAAE+wMQoFMuVEToPzc3o0Kv6nuAqhW+jostEACc7Ae0bX6qTAnFq/tiEgAwDAcewPiSAVlFAY9tpSy8XeRAIAANA/TxFCbm07wzCfAAAAAElFTkSuQmCC);
    background-position: left top;
    background-repeat: no-repeat; }
  .page-home .main .joinBright {
    padding-top: 24px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcIAAAAHCAYAAACbSk32AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFISURBVHgB7ZpNDsIgEIUhcdGlx/AYvf/KY7jssrsKVZsJmelAAcH6voRQfotTZ55grQHgz1iWZXDZYMpSY84W9+C4mrrU/Fw1117bLq3v55ky+3Bt8ztpfSdtnLU2Zn0q1oDucIFa+8LvtccEldjAk+J4qcHsqFO3EgMAQN/MQfJMJJ9J/nAiuonqaYVQ+NUfW+fhArXUN7Vemh8AAMB3WAXRpfvFVIDZ0dByKA5aORzPlaU6AM6EdKTUA0WOqDKobZsS8+fMkWLfo89Ci73cNR1Dr3uOx5/n4EXQ22radoROvG7mtXhqCEnAYsQLtEdzvBjHjHGqFAc/GgxyAm2NIDnToxUAAE+wMQoFMuVEToPzc3o0Kv6nuAqhW+jostEACc7Ae0bX6qTAnFq/tiEgAwDAcewPiSAVlFAY9tpSy8XeRAIAANA/TxFCbm07wzCfAAAAAElFTkSuQmCC);
    background-position: left top;
    background-repeat: no-repeat; }
  .page-home .main .no-margin {
    margin: 0 !important; }
  .page-home .main .fw-300 {
    font-weight: 300 !important; }
  .page-home .main .fw-400 {
    font-weight: 400 !important; }
  .page-home .main .fw-500 {
    font-weight: 500 !important; }
  .page-home .main .fw-600 {
    font-weight: 600 !important; }
  .page-home .main .text {
    line-height: 1.4;
    font-weight: 300;
    font-size: 40px;
    color: #fff;
    margin: 0 0 40px; }
  .page-home .main .second-text {
    line-height: 1.4;
    font-weight: 300;
    font-size: 24px;
    color: #fff;
    margin: 0 0 40px; }
  .page-home .main .pattern {
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: -1px;
    left: 0;
    background-image: url(/static/media/HeaderBigMask.6839c4ad.svg);
    background-size: 120%;
    background-position: center bottom;
    background-repeat: no-repeat; }
  .page-home .main .scene {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0; }
    .page-home .main .scene div {
      width: 100%;
      height: 100%; }
    .page-home .main .scene .star svg {
      position: absolute;
      right: 10%;
      top: 10%;
      opacity: .7; }
    .page-home .main .scene .earth svg {
      position: absolute;
      right: 6%;
      bottom: 24%;
      opacity: .5; }
    .page-home .main .scene .rocket svg {
      position: absolute;
      right: -5%;
      top: calc(45% - 60px);
      opacity: .5; }
    .page-home .main .scene .signs svg {
      position: absolute;
      right: 45%;
      bottom: 38%; }
    .page-home .main .scene .panda svg {
      display: block;
      height: auto;
      width: 320px;
      position: absolute;
      right: 26%;
      bottom: 35%; }
    .page-home .main .scene .book .image-box {
      position: absolute;
      right: 56%;
      bottom: 64%;
      max-width: 220px;
      height: auto;
      display: block; }
      .page-home .main .scene .book .image-box img {
        width: 100%;
        height: auto;
        display: block; }
      .page-home .main .scene .book .image-box a {
        pointer-events: auto;
        font-size: 14px;
        font-weight: 500;
        display: block;
        padding: 5px 14px;
        position: absolute;
        top: 20px;
        left: 90%;
        white-space: nowrap;
        letter-spacing: 0.02em;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -ms-border-radius: 8px;
        -moz-border-radius: 8px; }
        .page-home .main .scene .book .image-box a:after {
          left: -12px;
          bottom: 0;
          top: 0;
          margin: auto;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 14px 8px 0;
          border-color: transparent #EF4B6F transparent transparent; }
  .page-home .main .ibm {
    position: absolute;
    width: 100%;
    -webkit-animation: translate 18s linear infinite;
            animation: translate 18s linear infinite;
    height: 100px;
    bottom: 0; }
    .page-home .main .ibm svg {
      position: absolute;
      -webkit-animation: ibm 18s cubic-bezier(0, 0, 0, 0) infinite;
              animation: ibm 18s cubic-bezier(0, 0, 0, 0) infinite;
      display: block;
      height: auto;
      width: 100px; }

.page-home .registration-box {
  padding: 130px 0;
  background-color: #fff;
  background-image: url(/static/media/StarsTexture.6fa0b7d8.png);
  position: relative;
  background-size: contain; }
  .page-home .registration-box:before {
    bottom: 0;
    width: 100%;
    height: 140px;
    position: absolute;
    z-index: 1;
    content: '';
    background: #fcfcfc;
    background: linear-gradient(0deg, #f7f7f8 0%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcfcfc", endColorstr="#ffffff", GradientType=1); }
  .page-home .registration-box:after {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    content: '';
    background: white;
    background: radial-gradient(circle, white 0%, rgba(255, 255, 255, 0) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000", endColorstr="#ff0000", GradientType=1); }
  .page-home .registration-box .content-wrapper {
    position: relative;
    z-index: 6;
    max-width: 850px; }
  .page-home .registration-box-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    z-index: 3; }
  .page-home .registration-box-header {
    position: relative; }
    .page-home .registration-box-header:after {
      content: '';
      width: 100%;
      z-index: 2;
      height: 6px;
      bottom: 8px;
      position: absolute;
      left: 0;
      background-color: #240ABF; }
    .page-home .registration-box-header:before {
      content: '';
      width: 100%;
      z-index: 2;
      height: 10px;
      bottom: 0;
      position: absolute;
      left: 0;
      background-color: #fff; }
    .page-home .registration-box-header .icon {
      width: 200px; }
      .page-home .registration-box-header .icon svg {
        height: auto;
        width: 130px;
        display: block;
        margin: 0 auto; }
    .page-home .registration-box-header .title {
      color: #240ABF;
      font-weight: 300;
      font-size: 34px;
      width: calc(100% - 200px);
      text-align: center; }
  .page-home .registration-box-content {
    padding: 80px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    border-radius: 0 0 20px 20px;
    text-align: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    background-color: #fff;
    box-shadow: 10px 10px 50px 0 rgba(3, 27, 82, 0.5);
    -webkit-box-shadow: 10px 10px 50px 0 rgba(3, 27, 82, 0.5);
    -moz-box-shadow: 10px 10px 50px 0 rgba(3, 27, 82, 0.5); }
    .page-home .registration-box-content .title {
      color: #EF4B6F;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.35em; }
    .page-home .registration-box-content .count {
      line-height: 1.2;
      color: #240ABF;
      text-transform: uppercase;
      font-size: 90px;
      font-weight: 300;
      letter-spacing: 0.1em; }
    .page-home .registration-box-content .btn {
      margin: 20px auto 0; }

.page-home .join-box {
  text-align: center;
  padding: 200px 0;
  border-bottom: 1px solid #CCCCCC; }
  .page-home .join-box .content-wrapper {
    max-width: 930px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .page-home .join-box .item {
    max-width: 360px; }
  .page-home .join-box .icon {
    width: 100%;
    max-width: 320px;
    margin: 0 auto 20px; }
    .page-home .join-box .icon img {
      display: block;
      height: auto;
      width: 100%; }
  .page-home .join-box .title {
    font-size: 28px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em; }
  .page-home .join-box .description {
    font-size: 16px;
    font-weight: 400;
    color: #191970;
    line-height: 1.5;
    margin: 4px 0 35px; }
  .page-home .join-box .description-bold {
    font-size: 16px;
    font-weight: 600;
    color: #191970;
    line-height: 1.5; }
  .page-home .join-box .description-subtitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 20px;
    margin-top: 30px; }
  .page-home .join-box a.btn {
    margin: 0 auto; }

.page-home .about-box {
  padding: 250px 0;
  position: relative;
  overflow: hidden;
  background-color: #fff; }
  .page-home .about-box .rocket {
    position: absolute;
    top: 120px;
    z-index: 1; }
    .page-home .about-box .rocket svg {
      display: block;
      height: auto;
      width: 250px; }
  .page-home .about-box .content-wrapper {
    position: relative;
    z-index: 2;
    max-width: 1024px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .page-home .about-box .logo img {
    max-width: 280px;
    width: 100%;
    display: block;
    height: auto; }
  .page-home .about-box .text-box {
    max-width: 680px; }
    .page-home .about-box .text-box .title {
      color: #191970;
      font-size: 28px;
      font-weight: 400;
      letter-spacing: 0.025em; }
      .page-home .about-box .text-box .title-label {
        color: #191970;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.35em; }
    .page-home .about-box .text-box .description {
      margin: 20px 0 40px;
      color: #191970;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.02em; }
    .page-home .about-box .text-box .description-bold {
      color: #191970;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 0.02em; }
    .page-home .about-box .text-box .description-subtitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      margin-top: 30px; }
    .page-home .about-box .text-box .details-list-item {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
              align-items: flex-start; }
      .page-home .about-box .text-box .details-list-item:not(:last-child) {
        margin: 0 0 15px; }
      .page-home .about-box .text-box .details-list-item .icon {
        margin: 3px 10px 0 0;
        display: block; }
        .page-home .about-box .text-box .details-list-item .icon svg {
          width: 18px;
          height: 18px;
          display: block; }
      .page-home .about-box .text-box .details-list-item .text {
        font-size: 16px;
        font-weight: 600;
        color: #191970; }

.page-home .info-box {
  background-color: #EF4B6F;
  padding: 60px 0;
  color: #fff;
  text-align: center; }
  .page-home .info-box h2 {
    font-size: 36px;
    font-weight: 300;
    color: #fff;
    margin: 0 0 60px; }
  .page-home .info-box .info-list {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .page-home .info-box .info-list-item {
      max-width: 410px; }
    .page-home .info-box .info-list .icon {
      margin: 0 0 20px; }
      .page-home .info-box .info-list .icon svg {
        margin: 0 auto;
        display: block;
        height: 60px;
        width: auto; }
    .page-home .info-box .info-list .title {
      font-size: 18px;
      font-weight: 600; }
    .page-home .info-box .info-list .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5; }
    .page-home .info-box .info-list .description-subtitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      margin-top: 30px; }
    .page-home .info-box .info-list .description-bold {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5; }

.page-home .gallery-box {
  padding: 140px 0;
  position: relative; }
  .page-home .gallery-box-bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: transparent;
    background-image: url(/static/media/gallery-bg.e97bc7b7.png); }
  .page-home .gallery-box .content-wrapper {
    max-width: none;
    max-width: initial;
    position: relative;
    z-index: 2;
    text-align: center; }
  .page-home .gallery-box h2 {
    font-size: 36px;
    font-weight: 300;
    color: #191970;
    margin: 0 0 140px; }
  .page-home .gallery-box .slider-box .slick-list {
    max-width: 100vw;
    width: 100%; }
  .page-home .gallery-box .slider-box .slick-slider {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .page-home .gallery-box .slider-box .slick-arrow {
    cursor: pointer;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.4);
    /* Netscape */
    -moz-opacity: 0.4;
    /* Safari 1.x */
    -khtml-opacity: 0.4;
    /* Good browsers */
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out; }
    .page-home .gallery-box .slider-box .slick-arrow svg {
      height: auto;
      width: 36px;
      display: block; }
    .page-home .gallery-box .slider-box .slick-arrow:hover {
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=1);
      /* Netscape */
      -moz-opacity: 1;
      /* Safari 1.x */
      -khtml-opacity: 1;
      /* Good browsers */
      opacity: 1; }
  .page-home .gallery-box .slider-box .slick-prev {
    margin: 0 36px 0 0; }
  .page-home .gallery-box .slider-box .slick-next {
    margin: 0 0 0 36px; }
  .page-home .gallery-box .slider-box .slider-item {
    min-height: 330px;
    max-height: 330px;
    display: -webkit-flex !important;
    display: flex !important; }
    .page-home .gallery-box .slider-box .slider-item .image-wrap {
      margin: 5px;
      overflow: hidden;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
    .page-home .gallery-box .slider-box .slider-item img {
      cursor: pointer;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: scale(0.3);
              transform: scale(0.3); }

.page-home .sponsors-box {
  background-color: #fff;
  padding: 140px 0;
  text-align: center; }
  .page-home .sponsors-box .content-wrapper {
    max-width: 1024px; }
  .page-home .sponsors-box h2 {
    font-size: 36px;
    font-weight: 300;
    color: #191970;
    margin: 0 0 90px; }
  .page-home .sponsors-box .slick-slider {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .page-home .sponsors-box .slick-arrow {
    cursor: pointer;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.4);
    /* Netscape */
    -moz-opacity: 0.4;
    /* Safari 1.x */
    -khtml-opacity: 0.4;
    /* Good browsers */
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out; }
    .page-home .sponsors-box .slick-arrow svg {
      height: auto;
      width: 36px;
      display: block; }
    .page-home .sponsors-box .slick-arrow:hover {
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=1);
      /* Netscape */
      -moz-opacity: 1;
      /* Safari 1.x */
      -khtml-opacity: 1;
      /* Good browsers */
      opacity: 1; }
  .page-home .sponsors-box .slick-prev {
    margin: 0 36px 0 0; }
  .page-home .sponsors-box .slick-next {
    margin: 0 0 0 36px; }
  .page-home .sponsors-box .sponsors-list:not(.sponsors-list-slider) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    grid-gap: 40px 20px;
    gap: 40px 20px; }
  .page-home .sponsors-box .sponsors-list.sponsors-list-slider .slick-track {
    padding: 0 0 20px; }
  .page-home .sponsors-box .sponsors-item {
    width: calc(25% - 20px);
    position: relative;
    padding: 0 0 30px; }
  .page-home .sponsors-box .sponsors-icon img {
    height: auto;
    width: 200px;
    margin: 0 auto; }
  .page-home .sponsors-box .sponsors-logo {
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    background: #fff;
    width: 160px;
    box-sizing: border-box;
    padding: 10px 20px;
    box-shadow: 2px 2px 30px 0 rgba(5, 5, 100, 0.2);
    -webkit-box-shadow: 2px 2px 30px 0 rgba(5, 5, 100, 0.2);
    -moz-box-shadow: 2px 2px 30px 0 rgba(5, 5, 100, 0.2);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px; }
    .page-home .sponsors-box .sponsors-logo img {
      margin: 0 auto;
      max-width: 120px;
      width: 100%;
      height: auto;
      display: block; }

.page-home .learning-centers-box {
  padding: 140px 0 0;
  background-color: #fff; }
  .page-home .learning-centers-box .ant-table-pagination.ant-pagination {
    margin: 16px 0 0; }
  .page-home .learning-centers-box .top-container {
    padding: 0 0 140px; }
  .page-home .learning-centers-box .all-container {
    padding: 140px 0;
    background-color: #F7F7F8; }
    .page-home .learning-centers-box .all-container h2 {
      margin: 0 0 100px; }
  .page-home .learning-centers-box h2 {
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    color: #191970;
    margin: 0 0 40px; }
  .page-home .learning-centers-box .slick-slider {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-align-items: center;
            align-items: center; }
  .page-home .learning-centers-box .slick-arrow {
    cursor: pointer;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.4);
    /* Netscape */
    -moz-opacity: 0.4;
    /* Safari 1.x */
    -khtml-opacity: 0.4;
    /* Good browsers */
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out; }
    .page-home .learning-centers-box .slick-arrow svg {
      height: auto;
      width: 36px;
      display: block; }
    .page-home .learning-centers-box .slick-arrow:hover {
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=1);
      /* Netscape */
      -moz-opacity: 1;
      /* Safari 1.x */
      -khtml-opacity: 1;
      /* Good browsers */
      opacity: 1; }
  .page-home .learning-centers-box .slick-prev {
    margin: 0 36px 0 0; }
  .page-home .learning-centers-box .slick-next {
    margin: 0 0 0 36px; }
  .page-home .learning-centers-box .featured-list:not(.featured-list-slider) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    grid-gap: 40px 20px;
    gap: 40px 20px; }
    .page-home .learning-centers-box .featured-list:not(.featured-list-slider) .featured-item {
      width: calc(25% - 20px);
      position: relative; }
  .page-home .learning-centers-box .featured-list.featured-list-slider .slick-track {
    padding: 0 0 20px; }
  .page-home .learning-centers-box .featured-item {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .page-home .learning-centers-box .featured-item .title {
      width: 100%;
      font-weight: 700;
      color: #191970;
      font-size: 18px;
      margin: 10px 0; }
    .page-home .learning-centers-box .featured-item .description {
      width: 100%;
      font-weight: 400;
      color: #191970;
      font-size: 15px; }
    .page-home .learning-centers-box .featured-item .description-subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      margin-top: 30px; }
    .page-home .learning-centers-box .featured-item .description-bold {
      width: 100%;
      font-weight: 600;
      color: #191970;
      font-size: 15px; }
  .page-home .learning-centers-box .featured-icon {
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-height: 300px;
    min-height: 300px; }
    .page-home .learning-centers-box .featured-icon img {
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: scale(0.3);
              transform: scale(0.3); }
  .page-home .learning-centers-box .center-list-empty {
    font-weight: 400;
    font-size: 24px;
    color: #d5d5de;
    text-align: center;
    margin: 100px 0 50px; }
  .page-home .learning-centers-box .center-list-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #191970;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 12px 24px;
    background-color: transparent;
    border-bottom: 1px solid #f3f3f3;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out; }
    .page-home .learning-centers-box .center-list-item:last-child {
      border-bottom: 1px solid transparent; }
    @media (hover: hover) {
      .page-home .learning-centers-box .center-list-item:hover {
        background-color: #fff;
        border-bottom: 1px solid transparent;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -ms-border-radius: 10px;
        -moz-border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2);
        -webkit-box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2);
        -moz-box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2); } }
    .page-home .learning-centers-box .center-list-item div:not(:last-child) {
      box-sizing: border-box;
      padding: 0 10px 0 0; }
    .page-home .learning-centers-box .center-list-item div span.ant-typography {
      color: #191970; }
      .page-home .learning-centers-box .center-list-item div span.ant-typography-ellipsis {
        width: 100%; }
    .page-home .learning-centers-box .center-list-item div.name {
      max-width: 300px;
      width: 25%;
      font-weight: 700; }
    .page-home .learning-centers-box .center-list-item div.address {
      text-align: right;
      max-width: 565px;
      width: 60%; }

@media screen and (max-width: 1680px) {
  .page-home .main .content-wrapper {
    max-width: 1250px; }
  .page-home .main h1 {
    font-size: 36px;
    line-height: 1.2; }
  .page-home .main .text {
    font-size: 34px; }
  .page-home .main .second-text {
    font-size: 22px; }
  .page-home .main .scene .rocket svg {
    position: absolute;
    right: -5%;
    top: calc(45% - 60px);
    opacity: .5;
    display: block;
    height: auto;
    width: 300px; }
  .page-home .main .scene .signs svg {
    position: absolute;
    right: 48%;
    bottom: 40%;
    width: 140px;
    display: block;
    height: auto; }
  .page-home .main .scene .panda svg {
    display: block;
    height: auto;
    width: 260px;
    position: absolute;
    right: 26%;
    bottom: 35%; }
  .page-home .main .scene .book .image-box {
    right: 60%;
    max-width: 180px; }
  .page-home .main .ibm {
    -webkit-animation: translate1680 14s linear infinite;
            animation: translate1680 14s linear infinite; }
    .page-home .main .ibm svg {
      -webkit-animation: ibm 14s cubic-bezier(0, 0, 0, 0) infinite;
              animation: ibm 14s cubic-bezier(0, 0, 0, 0) infinite;
      width: 90px; }
  .page-home .main .view-more {
    bottom: 60px; }
  .page-home .gallery-box .slider-box .slick-arrow svg {
    width: 30px; }
  .page-home .gallery-box .slider-box .slider-item {
    min-height: 280px;
    max-height: 280px; }
  .page-home .sponsors-box .slick-arrow svg {
    width: 30px; }
  .page-home .learning-centers-box .slick-arrow svg {
    width: 30px; } }

@media screen and (max-width: 1366px) {
  .page-home .main h1 {
    font-size: 36px; }
  .page-home .main .text {
    font-size: 32px; }
  .page-home .main .second-text {
    font-size: 20px; }
  .page-home .main .competitionTitle {
    font-size: 18px; }
  .page-home .main .scene .book .image-box {
    max-width: 120px;
    right: 62%;
    bottom: 66%; }
  .page-home .main .scene .star svg {
    position: absolute;
    right: 10%;
    top: 14%;
    opacity: .7;
    display: block;
    height: auto;
    width: 80px; }
  .page-home .main .scene .earth svg {
    position: absolute;
    right: 6%;
    bottom: 26%;
    opacity: .5;
    display: block;
    height: auto;
    width: 70px; }
  .page-home .main .scene .rocket svg {
    position: absolute;
    right: -5%;
    top: calc(45% - 90px);
    opacity: .5;
    display: block;
    height: auto;
    width: 260px; }
  .page-home .main .scene .signs svg {
    position: absolute;
    right: 44%;
    bottom: 41%;
    width: 130px;
    display: block;
    height: auto; }
  .page-home .main .scene .panda svg {
    display: block;
    height: auto;
    width: 200px;
    position: absolute;
    right: 22%;
    bottom: 38%; }
  .page-home .main .view-more {
    bottom: 50px; }
  .page-home .about-box .rocket {
    top: 60px; }
    .page-home .about-box .rocket svg {
      width: 190px; }
  .page-home .gallery-box .slider-box .slider-item {
    min-height: 240px;
    max-height: 240px; } }

@media screen and (max-width: 1280px) {
  .page-home .registration-box {
    padding: 100px 0; }
    .page-home .registration-box .content-wrapper {
      max-width: 740px; }
    .page-home .registration-box-header .icon {
      width: 160px; }
      .page-home .registration-box-header .icon svg {
        width: 110px; }
    .page-home .registration-box-header .title {
      font-size: 30px; }
    .page-home .registration-box-content {
      padding: 70px 0; }
      .page-home .registration-box-content .title {
        font-size: 13px; }
      .page-home .registration-box-content .count {
        font-size: 80px; }
  .page-home .join-box {
    padding: 180px 0; }
    .page-home .join-box .content-wrapper {
      max-width: 810px; }
    .page-home .join-box .item {
      max-width: 360px; }
    .page-home .join-box .icon {
      max-width: 280px; }
    .page-home .join-box .title {
      font-size: 24px; }
    .page-home .join-box .description {
      margin: 6px 0 35px; }
    .page-home .join-box .description-bold {
      margin: 6px 0 35px;
      font-weight: 600; }
    .page-home .join-box .description-subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      margin-top: 30px; }
    .page-home .join-box a.btn {
      margin: 0 auto; }
  .page-home .about-box {
    padding: 180px 0; }
    .page-home .about-box .content-wrapper {
      max-width: 940px; }
    .page-home .about-box .logo img {
      max-width: 240px;
      width: 100%;
      display: block;
      height: auto; }
    .page-home .about-box .text-box {
      max-width: 600px; }
      .page-home .about-box .text-box .title {
        font-size: 24px; }
  .page-home .info-box {
    padding: 60px 0; }
    .page-home .info-box .content-wrapper {
      max-width: 1100px; }
    .page-home .info-box h2 {
      font-size: 32px;
      margin: 0 0 60px; }
    .page-home .info-box .info-list-item {
      max-width: 340px;
      padding: 0 20px; }
    .page-home .info-box .info-list .icon {
      margin: 0 0 20px; }
      .page-home .info-box .info-list .icon svg {
        height: 46px; }
    .page-home .info-box .info-list .title {
      font-size: 16px;
      font-weight: 600; }
    .page-home .info-box .info-list .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5; }
    .page-home .info-box .info-list .description-subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      margin-top: 30px; }
    .page-home .info-box .info-list .description-bold {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5; }
  .page-home .gallery-box {
    padding: 120px 0; }
    .page-home .gallery-box h2 {
      font-size: 32px;
      margin: 0 0 100px; }
  .page-home .sponsors-box {
    padding: 120px 0; }
    .page-home .sponsors-box h2 {
      font-size: 32px;
      margin: 0 0 80px; }
  .page-home .learning-centers-box {
    padding: 120px 0 0; }
    .page-home .learning-centers-box h2 {
      font-size: 32px;
      margin: 0 0 80px; }
    .page-home .learning-centers-box .top-container {
      padding: 0 0 120px; }
    .page-home .learning-centers-box .all-container {
      padding: 120px 0; } }

@media screen and (max-width: 1024px) {
  .page-home .main {
    background-attachment: scroll;
    background-attachment: initial; }
    .page-home .main h1 {
      font-size: 30px; }
    .page-home .main .text {
      font-size: 26px; }
    .page-home .main .second-text {
      font-size: 18px; }
    .page-home .main .competitionTitle {
      font-size: 16px; }
    .page-home .main .scene .book .image-box {
      max-width: 140px; }
    .page-home .main .scene .star svg {
      right: 9%;
      top: 10%;
      width: 60px; }
    .page-home .main .scene .earth svg {
      right: 6%;
      bottom: 24%;
      width: 60px; }
    .page-home .main .scene .rocket svg {
      top: calc(45% - 80px);
      width: 220px; }
    .page-home .main .scene .signs svg {
      right: 48%;
      bottom: 40%;
      width: 100px; }
    .page-home .main .scene .panda svg {
      width: 210px;
      right: 23%;
      bottom: 36%; }
    .page-home .main .view-more {
      bottom: 40px; }
    .page-home .main .ibm {
      -webkit-animation: translate1024 8s linear infinite;
              animation: translate1024 8s linear infinite; }
      .page-home .main .ibm svg {
        -webkit-animation: ibm 8s cubic-bezier(0, 0, 0, 0) infinite;
                animation: ibm 8s cubic-bezier(0, 0, 0, 0) infinite;
        width: 85px; }
  .page-home .registration-box-header .title {
    font-size: 26px; }
  .page-home .about-box {
    padding: 140px 0; }
    .page-home .about-box .content-wrapper {
      max-width: 900px; }
    .page-home .about-box .logo img {
      max-width: 220px; }
    .page-home .about-box .text-box {
      max-width: 600px; }
      .page-home .about-box .text-box .title {
        font-size: 24px; }
  .page-home .gallery-box .slider-box .slider-item {
    min-height: 200px;
    max-height: 200px; } }

@media screen and (max-width: 980px) {
  .page-home .sponsors-box .sponsors-list:not(.sponsors-list-slider) .sponsors-item {
    width: 31%; }
  .page-home .learning-centers-box .featured-list:not(.featured-list-slider) .featured-item {
    width: 31%; } }

@media screen and (max-width: 768px) {
  .page-home .main-wrapper {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    text-align: center; }
  .page-home .main .content-wrapper {
    padding: 0 0 60px; }
  .page-home .main div.btn,
  .page-home .main a.btn {
    margin: 0 auto; }
  .page-home .main h1 {
    font-size: 28px; }
  .page-home .main .afterRegAccess {
    background-position: center top; }
  .page-home .main .joinBright {
    background-position: center top; }
  .page-home .main .text {
    font-size: 24px; }
  .page-home .main .second-text {
    font-size: 16px; }
  .page-home .main .competitionTitle {
    font-size: 14px; }
  .page-home .main .scene .star svg {
    right: 9%;
    top: 10%;
    width: 60px; }
  .page-home .main .scene .earth svg {
    right: auto;
    right: initial;
    left: 6%;
    bottom: 24%;
    width: 60px; }
  .page-home .main .scene .rocket svg {
    top: 56%;
    width: 220px; }
  .page-home .main .scene .signs svg {
    right: auto;
    right: initial;
    left: 23%;
    bottom: auto;
    bottom: initial;
    top: 30%;
    width: 100px; }
  .page-home .main .scene .panda svg {
    width: 210px;
    right: 31%;
    bottom: 50%; }
  .page-home .main .scene .book .image-box {
    right: auto;
    right: initial;
    left: 8%;
    bottom: auto;
    bottom: initial;
    top: 9%; }
  .page-home .join-box {
    padding: 140px 0; }
    .page-home .join-box .content-wrapper {
      max-width: 700px; }
    .page-home .join-box .item {
      max-width: 340px; }
    .page-home .join-box .icon {
      max-width: 240px; }
    .page-home .join-box .title {
      font-size: 22px; }
  .page-home .about-box {
    padding: 100px 0; }
    .page-home .about-box .content-wrapper {
      display: block; }
    .page-home .about-box .logo img {
      margin: 0 0 50px;
      max-width: 240px; }
    .page-home .about-box .text-box {
      max-width: 100%; }
      .page-home .about-box .text-box .title {
        font-size: 22px; }
  .page-home .info-box {
    padding: 60px 0 70px; }
    .page-home .info-box h2 {
      font-size: 30px;
      margin: 0 0 60px; }
    .page-home .info-box .info-list {
      display: block; }
      .page-home .info-box .info-list-item {
        max-width: 500px;
        margin: 0 auto 50px; }
        .page-home .info-box .info-list-item:last-child {
          margin: 0 auto 0; }
  .page-home .gallery-box {
    padding: 100px 0; }
    .page-home .gallery-box h2 {
      font-size: 30px;
      margin: 0 0 80px; }
  .page-home .sponsors-box {
    padding: 100px 0; }
    .page-home .sponsors-box h2 {
      font-size: 30px;
      margin: 0 0 60px; }
    .page-home .sponsors-box .sponsors-list:not(.sponsors-list-slider) .sponsors-item {
      width: 48%; }
  .page-home .learning-centers-box {
    padding: 100px 0 0; }
    .page-home .learning-centers-box .all-container {
      padding: 100px 0; }
    .page-home .learning-centers-box .top-container {
      padding: 0 0 100px; }
    .page-home .learning-centers-box h2 {
      font-size: 30px;
      margin: 0 0 60px; }
    .page-home .learning-centers-box .featured-list:not(.featured-list-slider) .featured-item {
      width: 48%; }
    .page-home .learning-centers-box .center-list-item {
      padding: 10px 0;
      display: block;
      position: relative; }
      .page-home .learning-centers-box .center-list-item div {
        max-width: 100% !important;
        padding: 0;
        width: 100% !important; }
        .page-home .learning-centers-box .center-list-item div.address {
          text-align: left; } }

@media screen and (max-width: 720px) {
  .page-home .registration-box-header:after {
    bottom: 3px; }
  .page-home .registration-box-header .icon {
    width: 100px; }
    .page-home .registration-box-header .icon svg {
      width: 70px; }
  .page-home .registration-box-header .title {
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    width: calc(100% - 100px); }
  .page-home .registration-box-content {
    padding: 40px 0;
    display: block; }
    .page-home .registration-box-content .centers {
      margin: 0 0 40px; }
    .page-home .registration-box-content .students {
      margin: 0 0 40px; }
    .page-home .registration-box-content .title {
      font-size: 11px; }
    .page-home .registration-box-content .count {
      font-size: 60px; }
  .page-home .join-box {
    padding: 100px 0; }
    .page-home .join-box .content-wrapper {
      max-width: 100%;
      display: block; }
    .page-home .join-box .item {
      max-width: 360px;
      margin: 0 auto; }
      .page-home .join-box .item:not(:last-child) {
        margin: 0 auto 80px; }
  .page-home .about-box {
    padding: 80px 0; }
    .page-home .about-box .logo img {
      margin: 0 auto 50px;
      max-width: 160px; }
    .page-home .about-box .title {
      text-align: center; }
  .page-home .info-box .info-list-item {
    padding: 0; }
  .page-home .gallery-box {
    padding: 60px 0; }
    .page-home .gallery-box h2 {
      font-size: 30px;
      margin: 0 0 80px; }
  .page-home .sponsors-box {
    padding: 60px 0; }
    .page-home .sponsors-box h2 {
      font-size: 30px;
      margin: 0 0 60px; }
    .page-home .sponsors-box .sponsors-list:not(.sponsors-list-slider) .sponsors-item {
      width: 100%; }
  .page-home .learning-centers-box {
    padding: 60px 0 0; }
    .page-home .learning-centers-box .all-container {
      padding: 60px 0; }
      .page-home .learning-centers-box .all-container h2 {
        margin: 0 0 40px; }
    .page-home .learning-centers-box .top-container {
      padding: 0 0 60px; }
    .page-home .learning-centers-box h2 {
      font-size: 30px;
      margin: 0 0 60px; }
    .page-home .learning-centers-box .featured-list:not(.featured-list-slider) .featured-item {
      width: 100%; }
  .page-home .main {
    padding: 50px 0 140px; }
    .page-home .main .pattern {
      height: 30px; }
    .page-home .main .content-wrapper {
      padding: 0; }
    .page-home .main h1 {
      font-size: 22px; }
    .page-home .main .text {
      font-size: 20px;
      margin: 0 0 20px; }
    .page-home .main div.btn {
      font-size: 16px;
      width: 160px;
      height: 40px;
      line-height: 36px;
      border-radius: 40px;
      -webkit-border-radius: 40px;
      -ms-border-radius: 40px;
      -moz-border-radius: 40px; }
    .page-home .main .scene .star {
      display: none !important; }
      .page-home .main .scene .star svg {
        right: 9%;
        top: 10%;
        width: 60px; }
    .page-home .main .scene .earth {
      display: none !important; }
      .page-home .main .scene .earth svg {
        right: auto;
        right: initial;
        left: 6%;
        bottom: 24%;
        width: 60px; }
    .page-home .main .scene .rocket {
      display: none !important; }
      .page-home .main .scene .rocket svg {
        top: 56%;
        width: 220px; }
    .page-home .main .scene .signs svg {
      right: auto;
      right: initial;
      left: 14%;
      bottom: auto;
      bottom: initial;
      top: 40%;
      width: 70px; }
    .page-home .main .scene .panda svg {
      width: 140px;
      right: 10%;
      bottom: 45%; }
    .page-home .main .scene .book .image-box {
      left: 10%;
      max-width: 150px; } }

@media screen and (max-width: 520px) {
  .page-home .main .ibm {
    -webkit-animation: translate520 6s linear infinite;
            animation: translate520 6s linear infinite;
    height: 80px; }
    .page-home .main .ibm svg {
      -webkit-animation: ibm 6s cubic-bezier(0, 0, 0, 0) infinite;
              animation: ibm 6s cubic-bezier(0, 0, 0, 0) infinite;
      width: 75px; }
  .page-home .about-box .rocket {
    top: 30px; }
    .page-home .about-box .rocket svg {
      width: 150px; } }

.page-not-found {
  height: 100%;
  background-image: url(/static/media/main-bg.7ee7b05a.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .page-not-found header {
    position: absolute;
    z-index: 9; }
  .page-not-found footer {
    display: none; }
  .page-not-found .page-container {
    padding: 100px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    box-sizing: border-box;
    height: 100vh; }
  .page-not-found .content-wrapper {
    max-width: 800px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .page-not-found .left img {
    max-width: 220px; }
  .page-not-found .right {
    max-width: 420px; }
  .page-not-found h1 {
    font-size: 48px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
    margin: 0 0 30px; }
  .page-not-found .text {
    font-size: 24px;
    font-weight: 400;
    color: #fff; }

@media screen and (max-width: 1680px) {
  .page-not-found .page-container {
    padding: 80px 0; }
  .page-not-found .content-wrapper {
    max-width: 740px; }
  .page-not-found .left img {
    max-width: 190px; }
  .page-not-found .right {
    max-width: 420px; }
  .page-not-found h1 {
    font-size: 46px;
    margin: 0 0 20px; }
  .page-not-found .text {
    font-size: 22px; } }

@media screen and (max-width: 768px) {
  .page-not-found .page-container {
    padding: 80px 0; }
  .page-not-found .content-wrapper {
    max-width: 600px; }
  .page-not-found .left img {
    max-width: 170px; }
  .page-not-found .right {
    max-width: 350px; }
  .page-not-found h1 {
    font-size: 40px;
    margin: 0 0 20px; }
  .page-not-found .text {
    font-size: 20px; } }

@media screen and (max-width: 720px) {
  .page-not-found .page-container {
    padding: 40px 0; }
  .page-not-found .content-wrapper {
    max-width: 600px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: center;
            align-content: center;
    text-align: center; }
  .page-not-found .left {
    width: 100%;
    margin: 0 0 40px; }
    .page-not-found .left img {
      max-width: 90px; }
  .page-not-found .right {
    max-width: 100%; }
  .page-not-found h1 {
    font-size: 20px;
    margin: 0 0 10px; }
  .page-not-found .text {
    font-size: 16px; } }

.page-learning-centers {
  background-color: #FCFCFC; }
  .page-learning-centers header {
    background-color: #171058;
    background-image: url(/static/media/HeaderSmallMask.d2dc46f9.svg);
    background-size: 120%;
    background-position: center 101%;
    background-repeat: no-repeat;
    padding: 24px 0 70px; }
  .page-learning-centers .page-container {
    min-height: calc(100vh - 363px);
    box-sizing: border-box;
    padding: 40px 0; }
  .page-learning-centers .field-box {
    margin: 0 0 50px;
    max-width: 700px;
    min-width: 240px;
    width: 100%; }
  .page-learning-centers .top-container {
    margin-bottom: 120px; }
  .page-learning-centers h2 {
    font-size: 28px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em;
    text-align: center; }
  .page-learning-centers .all-container h2 {
    margin: 0 0 50px; }
  .page-learning-centers .featured-list:not(.featured-list-slider) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    grid-gap: 40px;
    gap: 40px; }
    .page-learning-centers .featured-list:not(.featured-list-slider) .featured-item {
      width: calc(33% - 40px);
      position: relative; }
  .page-learning-centers .featured-item {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .page-learning-centers .featured-item .title {
      width: 100%;
      font-weight: 700;
      color: #191970;
      font-size: 18px;
      margin: 10px 0; }
    .page-learning-centers .featured-item .description {
      width: 100%;
      font-weight: 400;
      color: #191970;
      font-size: 15px; }
    .page-learning-centers .featured-item .description-subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      margin-top: 30px; }
    .page-learning-centers .featured-item .description-bold {
      width: 100%;
      font-weight: 600;
      color: #191970;
      font-size: 15px; }
  .page-learning-centers .featured-icon {
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-height: 200px;
    min-height: 200px; }
    .page-learning-centers .featured-icon img {
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: scale(0.3);
              transform: scale(0.3); }
  .page-learning-centers .center-list-empty {
    font-weight: 400;
    font-size: 24px;
    color: #d5d5de;
    text-align: center;
    margin: 100px 0 50px; }
  .page-learning-centers .center-list-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #191970;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 12px 24px;
    background-color: transparent;
    border-bottom: 1px solid #f3f3f3;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out; }
    .page-learning-centers .center-list-item:last-child {
      border-bottom: 1px solid transparent; }
    @media (hover: hover) {
      .page-learning-centers .center-list-item:hover {
        background-color: #fff;
        border-bottom: 1px solid transparent;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -ms-border-radius: 10px;
        -moz-border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2);
        -webkit-box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2);
        -moz-box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2); } }
    .page-learning-centers .center-list-item div:not(:last-child) {
      box-sizing: border-box;
      padding: 0 10px 0 0; }
    .page-learning-centers .center-list-item div span.ant-typography {
      color: #191970; }
      .page-learning-centers .center-list-item div span.ant-typography-ellipsis {
        width: 100%; }
    .page-learning-centers .center-list-item div.name {
      max-width: 300px;
      width: 25%;
      font-weight: 700; }
    .page-learning-centers .center-list-item div.address {
      text-align: right;
      max-width: 565px;
      width: 60%; }

@media screen and (max-width: 1680px) {
  .page-learning-centers .slick-arrow svg {
    width: 30px; } }

@media screen and (max-width: 1280px) {
  .page-learning-centers h2 {
    font-size: 32px; } }

@media screen and (max-width: 980px) {
  .page-learning-centers .featured-list:not(.featured-list-slider) .featured-item {
    width: calc(33% - 40px); } }

@media screen and (max-width: 768px) {
  .page-learning-centers h2 {
    font-size: 30px; }
  .page-learning-centers .featured-list:not(.featured-list-slider) .featured-item {
    width: 48%; }
  .page-learning-centers .center-list-item {
    padding: 10px 0;
    display: block;
    position: relative; }
    .page-learning-centers .center-list-item div {
      max-width: 100% !important;
      padding: 0;
      width: 100% !important; }
      .page-learning-centers .center-list-item div.address {
        text-align: left; } }

@media screen and (max-width: 720px) {
  .page-learning-centers .featured-list:not(.featured-list-slider) .featured-item {
    width: 100%; } }

.page-results {
  background-color: #FCFCFC; }
  .page-results header {
    background-color: #171058;
    background-image: url(/static/media/HeaderSmallMask.d2dc46f9.svg);
    background-size: 120%;
    background-position: center 101%;
    background-repeat: no-repeat;
    padding: 24px 0 70px; }
  .page-results .page-container {
    min-height: calc(100vh - 363px);
    box-sizing: border-box;
    padding: 40px 0; }
  .page-results h2 {
    font-size: 28px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em;
    text-align: center;
    margin: 0 0 50px; }
  .page-results .filter-box {
    display: -webkit-flex;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin: 0 0 40px; }
    .page-results .filter-box .field-box {
      width: 33%; }
      .page-results .filter-box .field-box .ant-select {
        width: 100%; }
  .page-results .results-list-box {
    position: relative; }
  .page-results .results-list-empty {
    font-weight: 400;
    font-size: 24px;
    color: #d5d5de;
    text-align: center;
    margin: 100px 0 50px; }
  .page-results .results-list-header {
    right: 0;
    top: 0;
    -webkit-flex: 1 1;
            flex: 1 1;
    position: sticky;
    position: -webkit-sticky;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #191970;
    padding: 12px 24px;
    background-color: #fafafa;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #f3f3f3; }
    .page-results .results-list-header div {
      font-weight: 700; }
      .page-results .results-list-header div.name {
        max-width: 270px;
        width: 20%; }
      .page-results .results-list-header div.grade {
        max-width: 120px;
        width: 15%; }
      .page-results .results-list-header div.center {
        max-width: 320px;
        width: 30%; }
      .page-results .results-list-header div.rank {
        max-width: 150px;
        width: 18%; }
      .page-results .results-list-header div.award {
        max-width: 240px;
        width: 20%; }
      .page-results .results-list-header div.state {
        max-width: 120px;
        width: 10%; }
  .page-results .results-list-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #191970;
    padding: 12px 24px;
    background-color: transparent;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out; }
    .page-results .results-list-item:last-child {
      border-bottom: 1px solid transparent; }
    @media (hover: hover) {
      .page-results .results-list-item:hover {
        background-color: #fff;
        border-bottom: 1px solid transparent;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -ms-border-radius: 10px;
        -moz-border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2);
        -webkit-box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2);
        -moz-box-shadow: 0 0 20px 0 rgba(5, 5, 100, 0.2); } }
    .page-results .results-list-item div:not(:last-child) {
      box-sizing: border-box;
      padding: 0 10px 0 0; }
    .page-results .results-list-item div span.ant-typography {
      color: #191970; }
      .page-results .results-list-item div span.ant-typography-ellipsis {
        width: 100%; }
    .page-results .results-list-item div.name {
      max-width: 270px;
      width: 20%;
      font-weight: 700; }
    .page-results .results-list-item div.grade {
      max-width: 120px;
      width: 15%; }
    .page-results .results-list-item div.center {
      max-width: 320px;
      width: 30%; }
    .page-results .results-list-item div.rank {
      max-width: 150px;
      width: 18%; }
    .page-results .results-list-item div.award {
      max-width: 240px;
      width: 20%; }
    .page-results .results-list-item div.state {
      max-width: 120px;
      width: 10%; }

@media screen and (max-width: 767px) {
  .page-results .filter-box {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 0 20px; }
    .page-results .filter-box .field-box {
      width: 100%; }
  .page-results .results-list-header {
    display: none; }
  .page-results .results-list-item {
    padding: 10px 0;
    display: block;
    position: relative; }
    .page-results .results-list-item div {
      max-width: 100% !important;
      padding: 0;
      width: 100% !important; } }

